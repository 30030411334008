import React, { Fragment, useContext } from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";

import { MainSection1Context, MainSection2Context, MainSection3Context, MainSection5Context } from "./MainContainer";

const Section1BackgroundBox = styled(Box)(({ theme, background }) => ({
  // backgroundColor: theme.palette.background.navy,
  backgroundImage: `url(${background})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  width: "100vw",
  height: "100vh",
  scrollSnapAlign: "center",
}));

const Section1ButtonGridContainer = styled(Grid)({
  position: "absolute",
  top: 0,
  zIndex: 10,
  height: "100%",
  width: "100%",
});

const Section1ScrollDownBar = styled(Box)({
  height: "50px",
  width: 2,
  backgroundColor: "#FFFFFF",
});

const Section1TitleTypo = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[50],
}));

const Section2Container = styled(Container)({
  padding: "200px 150px 200px 170px",
});

const Section2BackgroundBox = styled(Box)({
  width: "100%",
});

const Section2Divider = styled(Box)({
  width: "154px",
  height: 2,
  backgroundColor: "#000000",
});

const Section2DurationDivider = styled(Box)({
  width: "182px",
  height: 2,
  backgroundColor: "#000000",
});

const Section2UnitTypo = styled(Typography)({
  marginBottom: "12px",
});

const Section2CaptionTypo = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[400],
}));

const Section3TypoGridItem = styled(Grid)({
  position: "absolute",
  top: 0,
  height: "100%",
});

const Section3BackgroundImage = styled("img")({
  width: "100%",
  height: "100%",
});

const Section4Container = styled(Container)({
  padding: "180px 180px 185px 170px",
});

const Section5BackgroundBox = styled(Box)({
  backgroundColor: "#F8F8F8",
  width: "100%",
});

const Section5Container = styled(Container)({
  padding: "205px 210px 180px 170px",
});

const CustomContainer = styled(Container)({
  height: "100%",
  position: "relative",
});

const CustomGridContainer = styled(Grid)({
  height: "100%",
});

const Section5LogoImageBox = styled(Box)({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});

const Section5LogoImage = styled("img")({
  width: "100%",
});

export default function MainBlock() {
  return (
    <Fragment>
      <MainSection1Block />
      <MainSection2Block />
      <MainSection3Block />
      <MainSection4Block />
      <MainSection5Block />
    </Fragment>
  );
}

export function MainSection1Block() {
  const { section1Background, moveToSection2Func } = useContext(MainSection1Context);

  return (
    <Section1BackgroundBox background={section1Background}>
      <CustomContainer maxWidth="lg">
        <CustomGridContainer container justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Section1TitleTypo variant="h2" align="center" textTransform="uppercase">
              함께. 기획부터
              <br />
              디자인, 개발, QA, 런칭까지
            </Section1TitleTypo>
          </Grid>
        </CustomGridContainer>
        <Section1ButtonGridContainer container justifyContent="center" alignContent="flex-end">
          <Grid item xs="auto">
            <ButtonBase onClick={() => moveToSection2Func()}>
              <Grid container justifyContent="center" rowGap={1.4}>
                <Grid item xs={12}>
                  <Typography variant="body1" color="#FFFFFF">
                    Scroll Down
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Section1ScrollDownBar />
                </Grid>
              </Grid>
            </ButtonBase>
          </Grid>
        </Section1ButtonGridContainer>
      </CustomContainer>
    </Section1BackgroundBox>
  );
}

export function MainSection2Block() {
  const { section2ContainerRef, techStacks } = useContext(MainSection2Context);

  return (
    <Section2BackgroundBox ref={section2ContainerRef}>
      <Section2Container disableGutters maxWidth="xl">
        <CustomGridContainer container columnSpacing={21} rowGap={12}>
          <Grid container item xs={12} lg={6} alignContent="center">
            <Grid container item rowGap={12}>
              <Grid container item rowGap={3}>
                <Grid item xs={12}>
                  <Typography variant="h3" textTransform="uppercase">
                    team omega
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" lineHeight="32px">
                    스타트업에 최적화된 쉽지만 강력한 개발 스택, 그리고 당신의 첫번째 개발팀.
                    <br />
                    캐피탈레터의 오메가를 소개합니다.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item columnSpacing={4} justifyContent="space-between" alignItems="center">
                <Grid item xs="auto">
                  <img src={techStacks.googleCloud} alt="Google Cloud" />
                </Grid>
                <Grid item xs="auto">
                  <img src={techStacks.cloudFunction} alt="Cloud Function" />
                </Grid>
                <Grid item xs="auto">
                  <img src={techStacks.firebase} alt="Firebase" />
                </Grid>
                <Grid item xs="auto">
                  <img src={techStacks.react} alt="React" />
                </Grid>
                <Grid item xs="auto">
                  <img src={techStacks.mui} alt="Material Ui" />
                </Grid>
                <Grid item xs="auto">
                  <img src={techStacks.typeSense} alt="Type Sense" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} lg={6} justifyContent="space-between" alignContent="flex-end">
            <Grid container item xs={4} rowGap={5}>
              <Grid container item rowGap={1.5}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">평균 경력</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Section2Divider />
                </Grid>
              </Grid>
              <Grid container item alignItems="flex-end">
                <Grid item xs="auto">
                  <Typography variant="h2" fontSize="72px">
                    10
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Section2UnitTypo variant="h4" fontSize="40px">
                    년
                  </Section2UnitTypo>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={4} rowGap={5}>
              <Grid container item rowGap={1.5}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">진행 프로젝트</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Section2Divider />
                </Grid>
              </Grid>
              <Grid container item alignItems="flex-end">
                <Grid item xs="auto">
                  <Typography variant="h2" fontSize="72px">
                    100
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Section2UnitTypo variant="h4" fontSize="40px">
                    건+
                  </Section2UnitTypo>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={4} rowGap={5}>
              <Grid container item rowGap={1.5}>
                <Grid container item columnGap={1}>
                  <Grid item xs="auto">
                    <Typography variant="subtitle2">평균 제작기간</Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <Section2CaptionTypo variant="caption1">&#40;22.12 기준&#41;</Section2CaptionTypo>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Section2DurationDivider />
                </Grid>
              </Grid>
              <Grid container item alignItems="flex-end">
                <Grid item xs="auto">
                  <Typography variant="h2" fontSize="72px">
                    60
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Section2UnitTypo variant="h4" fontSize="40px">
                    일
                  </Section2UnitTypo>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CustomGridContainer>
      </Section2Container>
    </Section2BackgroundBox>
  );
}

export function MainSection3Block(props) {
  const { section3Background } = useContext(MainSection3Context);

  return (
    <CustomContainer disableGutters maxWidth={false}>
      <CustomGridContainer container>
        <Section3BackgroundImage src={section3Background} alt="팀미션 배경" />
        <Section3TypoGridItem container item xs={12} justifyContent="center" alignContent="center" rowGap={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" align="center" color="#4AC799">
              TEAM MISSION
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" align="center" color="#FFFFFF">
              일회성 개발 외주가 아닌 함께 지속적으로 성장해 나가며
              <br />
              온전히 비지니스에만 집중할 수 있도록, 첫 개발팀이 되어 주는 것.
              <br />
              이것이 팀 오메가의 미션입니다
            </Typography>
          </Grid>
        </Section3TypoGridItem>
      </CustomGridContainer>
    </CustomContainer>
  );
}

export function MainSection4Block() {
  return (
    <Section4Container disableGutters maxWidth="xl">
      <CustomGridContainer container alignContent="center" rowGap={12}>
        <Grid item xs={12} lg={6}>
          <Typography variant="h3" lineHeight="63.55px">
            팀 오메가는
            <br /> 비지니스 파트너로서 <br />
            새로운 플랫폼을 만들고 있습니다
          </Typography>
        </Grid>
        {/* <Grid container item xs={12} lg={6} justifyContent="center">
          <Grid container item xs={4} alignContent="flex-end" rowGap={3}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="#238C66">
                2021.07
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" lineHeight="37.07px">
                팀 캐피탈레터
                <br />
                결성
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={4} alignContent="flex-end" rowGap={3}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="#238C66">
                2022
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" lineHeight="37.07px">
                4개 플랫폼
                <br />
                런칭
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={4} alignContent="flex-end" rowGap={3}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="#238C66">
                2023
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" lineHeight="37.07px">
                3개 플랫폼
                <br />
                런칭
                <Typography variant="caption"> (23.06 현재)</Typography>
              </Typography>
            </Grid>
          </Grid>
        </Grid> */}
      </CustomGridContainer>
    </Section4Container>
  );
}

export function MainSection5Block() {
  const { partners } = useContext(MainSection5Context);

  const partnerLogo = (image, alt) => (
    <Grid item xs={4} key={alt}>
      <Section5LogoImageBox>
        <Section5LogoImage src={image} alt={alt} />
      </Section5LogoImageBox>
    </Grid>
  );

  return (
    <Section5BackgroundBox>
      <Section5Container disableGutters maxWidth="xl">
        <CustomGridContainer container rowGap={12} alignContent="flex-start" alignItems="center">
          <Grid item xs={12} lg={6}>
            <Typography variant="h3" lineHeight="63.55px">
              <Typography variant="h3" component="span" color="#238C66">
                다양한 플랫폼
              </Typography>
              이
              <br />팀 오메가와 함께 합니다
            </Typography>
          </Grid>
          <Grid container item xs={12} lg={6} rowGap={9} columnSpacing={10}>
            {partners.map((partner) => partnerLogo(partner.image, partner.alt))}
          </Grid>
        </CustomGridContainer>
      </Section5Container>
    </Section5BackgroundBox>
  );
}
