import React from "react";

import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";

const DefaultContainer = styled(Container)({
  minHeight: "75vh",
});

export default function ProjectsBlock() {
  return <DefaultContainer />;
}
