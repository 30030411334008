import React, { useEffect, useRef } from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const ContactBackgroundBox = styled(Box)({
  paddingTop: "75px",
  backgroundColor: "#FFFFFF",
  width: "100vw",
  height: "80vh",
});

const CustomContainer = styled(Container)({
  height: "100%",
});

const ContainerPaper = styled(Paper)({
  padding: "30px",
  borderRadius: "30px",
  background: "linear-gradient(90deg, #FFFFFF 73%, #238C66 27%)",
});

const MapBox = styled(Box)({
  height: "40vh",
});

export default function ContactBlock() {
  const mapRef = useRef(null);

  useEffect(() => {
    const { naver } = window;
    if (!mapRef.current || !naver) return;

    const geolocation = [37.5101809, 126.9460334];
    const location = new naver.maps.LatLng(geolocation[0], geolocation[1]);

    const mapOptions = {
      center: location,
      zoom: 15,
      zoomControl: true,
      scaleControl: false,
    };
    const map = new naver.maps.Map(mapRef.current, mapOptions);
    new naver.maps.Marker({
      position: location,
      map,
    });
  }, []);

  return (
    <ContactBackgroundBox>
      <CustomContainer maxWidth="lg">
        <ContainerPaper elevation={7}>
          <Grid container columnSpacing={2} rowGap={2}>
            <Grid container item direction="column" rowGap={2}>
              <Grid item xs="auto">
                <Typography variant="h4" color="primary" fontFamily={["LatoBlackItalic", "LineSeedKR", "Noto Sans KR"].join(",")}>
                  CONTACT
                </Typography>
              </Grid>
              <Grid container item xs direction="column" justifyContent="center" rowGap={1}>
                <Grid container item alignItems="center" columnSpacing={1}>
                  <Grid item xs="auto">
                    <Typography variant="subtitle2" color="primary">
                      E-mail
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body1">contact@kapitalletter.com</Typography>
                  </Grid>
                </Grid>
                <Grid container item alignItems="center" columnSpacing={1}>
                  <Grid item xs="auto">
                    <Typography variant="subtitle2" color="primary">
                      Phone
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body1">02 812 3399</Typography>
                  </Grid>
                </Grid>
                <Grid container item alignItems="center" columnSpacing={1}>
                  <Grid item xs="auto">
                    <Typography variant="subtitle2" color="primary">
                      Address
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body1">서울시 동작구 만양로 8길 59, 3층</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <MapBox ref={mapRef} />
            </Grid>
          </Grid>
        </ContainerPaper>
      </CustomContainer>
    </ContactBackgroundBox>
  );
}
