import React, { useEffect, useRef, createContext } from "react";

import { backgroundUrl, partnersLogoUrl, techStackUrl } from "../../configs/urls";
import MainBlock from "./MainBlock";
import MainMobileBlock from "./MainMobileBlock";

import useMediaQuery from "@mui/material/useMediaQuery";

export const MainSection1Context = createContext();
export const MainSection2Context = createContext();
export const MainSection3Context = createContext();
export const MainSection5Context = createContext();

export default function MainContainer() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const section2ContainerRef = useRef();

  const moveToSection2Func = () => {
    section2ContainerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const partners = [
    { image: partnersLogoUrl.mySignal, alt: "mySignal" },
    { image: partnersLogoUrl.doubleStudio, alt: "doubleStudio" },
    { image: partnersLogoUrl.koreaUniv, alt: "koreaUniv" },
    { image: partnersLogoUrl.linkey, alt: "linkey" },
    { image: partnersLogoUrl.on1016, alt: "on1016" },
    { image: partnersLogoUrl.danaeum, alt: "danaeum" },
  ];

  const overLg = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const mainSection1Set = {
    section1Background: backgroundUrl.mainSection1,
    moveToSection2Func: moveToSection2Func,
  };

  const mainSection2Set = {
    section2ContainerRef: section2ContainerRef,
    techStacks: techStackUrl,
  };

  const mainSection3Set = {
    section3Background: backgroundUrl.mainSection3,
  };

  const mainSection5Set = {
    partners: partners,
  };

  if (overLg) {
    return (
      <MainSection1Context.Provider value={mainSection1Set}>
        <MainSection2Context.Provider value={mainSection2Set}>
          <MainSection3Context.Provider value={mainSection3Set}>
            <MainSection5Context.Provider value={mainSection5Set}>
              <MainBlock />
            </MainSection5Context.Provider>
          </MainSection3Context.Provider>
        </MainSection2Context.Provider>
      </MainSection1Context.Provider>
    );
  } else {
    return (
      <MainSection1Context.Provider value={mainSection1Set}>
        <MainSection2Context.Provider value={mainSection2Set}>
          <MainSection3Context.Provider value={mainSection3Set}>
            <MainSection5Context.Provider value={mainSection5Set}>
              <MainMobileBlock />
            </MainSection5Context.Provider>
          </MainSection3Context.Provider>
        </MainSection2Context.Provider>
      </MainSection1Context.Provider>
    );
  }
}
