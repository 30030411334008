import React, { Fragment, useContext } from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";

import { MainSection1Context, MainSection2Context, MainSection3Context, MainSection5Context } from "./MainContainer";

const Section1BackgroundBox = styled(Box)(({ theme, background }) => ({
  // backgroundColor: theme.palette.background.navy,
  backgroundImage: `url(${background})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  width: "100vw",
  height: "100vh",
  scrollSnapAlign: "center",
}));

const Section1ButtonGridContainer = styled(Grid)({
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: 10,
  height: "100%",
  width: "100vw",
});

const Section1ScrollDownBar = styled(Box)({
  height: "50px",
  width: 2,
  backgroundColor: "#FFFFFF",
});

const Section1TitleTypo = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[50],
}));

const Section2Container = styled(Container)({
  padding: "40px 20px",
  height: "100%",
});

const Section2BackgroundBox = styled(Box)({
  width: "100vw",
  height: "100vh",
});

const Section2UnitTypo = styled(Typography)({
  marginBottom: "12px",
});

const Section2CaptionTypo = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[400],
}));

const Section2LogoImage = styled("img")({
  width: "100%",
});

const Section3BackgroundBox = styled(Box)(({ theme, background }) => ({
  // backgroundColor: theme.palette.background.navy,
  backgroundImage: `url(${background})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  width: "100vw",
  height: "50vh",
}));

const Section3TypoGridItem = styled(Grid)({
  position: "absolute",
  top: 0,
  height: "100%",
});

const Section4BackgroundBox = styled(Box)({
  width: "100vw",
  height: "50vh",
  backgroundColor: "#FFFFFF",
});

const Section4Container = styled(Container)({
  padding: "40px 20px",
  height: "100%",
});

const Section5BackgroundBox = styled(Box)({
  backgroundColor: "#F8F8F8",
  width: "100%",
  height: "100vh",
});

const Section5Container = styled(Container)({
  padding: "40px 20px",
  height: "100%",
});

const CustomContainer = styled(Container)({
  height: "100%",
  position: "relative",
});

const CustomGridContainer = styled(Grid)({
  height: "100%",
});

const Section5LogoImageBox = styled(Box)({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});

const Section5LogoImage = styled("img")({
  width: "100%",
});

export default function MainBlock() {
  return (
    <Fragment>
      <MainSection1Block />
      <MainSection2Block />
      <MainSection3Block />
      <MainSection4Block />
      <MainSection5Block />
    </Fragment>
  );
}

export function MainSection1Block() {
  const { section1Background, moveToSection2Func } = useContext(MainSection1Context);

  return (
    <Section1BackgroundBox background={section1Background}>
      <CustomContainer>
        <CustomGridContainer container justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Section1TitleTypo fontSize="40px" fontWeight={700} lineHeight="59.96px" align="center" textTransform="uppercase">
              기획부터 함께
              <br />
              디자인/개발/
              <br />
              QA/런칭까지
            </Section1TitleTypo>
          </Grid>
        </CustomGridContainer>
        <Section1ButtonGridContainer container justifyContent="center" alignContent="flex-end">
          <Grid item xs="auto">
            <ButtonBase onClick={() => moveToSection2Func()}>
              <Grid container justifyContent="center" rowGap={1.4}>
                <Grid item xs={12}>
                  <Typography variant="body1" color="#FFFFFF">
                    Scroll Down
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Section1ScrollDownBar />
                </Grid>
              </Grid>
            </ButtonBase>
          </Grid>
        </Section1ButtonGridContainer>
      </CustomContainer>
    </Section1BackgroundBox>
  );
}

export function MainSection2Block() {
  const { section2ContainerRef, techStacks } = useContext(MainSection2Context);

  return (
    <Section2BackgroundBox ref={section2ContainerRef}>
      <Section2Container disableGutters>
        <CustomGridContainer container rowGap={6} justifyContent="center" alignContent="center">
          <Grid container item xs={12} alignContent="center">
            <Grid container item rowGap={6}>
              <Grid container item rowGap={3}>
                <Grid item xs={12}>
                  <Typography fontSize="40px" fontWeight={700} lineHeight="48px" textTransform="uppercase" align="center">
                    team omega
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" lineHeight="28px" align="center">
                    스타트업에 최적화된 쉽지만 강력한 개발 스택,
                    <br />
                    그리고 당신의 첫번째 개발팀.
                    <br />
                    캐피탈레터의 오메가를 소개합니다.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item columnSpacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={1.5}>
                  <Section2LogoImage src={techStacks.googleCloud} alt="Google Cloud" />
                </Grid>
                <Grid item xs={1.5}>
                  <Section2LogoImage src={techStacks.cloudFunction} alt="Cloud Function" />
                </Grid>
                <Grid item xs={1.5}>
                  <Section2LogoImage src={techStacks.firebase} alt="Firebase" />
                </Grid>
                <Grid item xs={1.5}>
                  <Section2LogoImage src={techStacks.react} alt="React" />
                </Grid>
                <Grid item xs={1.5}>
                  <Section2LogoImage src={techStacks.mui} alt="Material Ui" />
                </Grid>
                <Grid item xs={1.5}>
                  <Section2LogoImage src={techStacks.typeSense} alt="Type Sense" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={7} alignContent="flex-end" rowGap={3}>
            <Grid container item justifyContent="center">
              <Grid item xs={6}>
                <Typography variant="subtitle2">평균 경력</Typography>
              </Grid>
              <Grid container item xs={6} alignContent="center">
                <Grid item xs="auto">
                  <Typography variant="h4">10</Typography>
                </Grid>
                <Grid item xs="auto">
                  <Section2UnitTypo variant="h5">년</Section2UnitTypo>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item xs={6}>
                <Typography variant="subtitle2">진행 프로젝트</Typography>
              </Grid>
              <Grid container item xs={6} alignContent="center">
                <Grid item xs="auto">
                  <Typography variant="h4">100</Typography>
                </Grid>
                <Grid item xs="auto">
                  <Section2UnitTypo variant="h5">건+</Section2UnitTypo>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid container item xs={6}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">평균 제작기간</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Section2CaptionTypo variant="caption1">&#40;22.12 기준&#41;</Section2CaptionTypo>
                </Grid>
              </Grid>
              <Grid container item xs={6} alignContent="center">
                <Grid item xs="auto">
                  <Typography variant="h4">60</Typography>
                </Grid>
                <Grid item xs="auto">
                  <Section2UnitTypo variant="h5">일</Section2UnitTypo>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CustomGridContainer>
      </Section2Container>
    </Section2BackgroundBox>
  );
}

export function MainSection3Block() {
  const { section3Background } = useContext(MainSection3Context);

  return (
    <CustomContainer disableGutters maxWidth={false}>
      <Section3BackgroundBox container background={section3Background}>
        <Section3TypoGridItem container item xs={12} justifyContent="center" alignContent="center" rowGap={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" align="center" color="#4AC799">
              TEAM MISSION
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" align="center" color="#FFFFFF" lineHeight="1.5" fontFamily={["LINESeedSansKR-Regular", "Noto Sans KR"].join(",")}>
              일회성 개발 외주가 아닌
              <br />
              함께 지속적으로 성장해 나가며
              <br />
              온전히 비지니스에만 집중할 수 있도록,
              <br />
              첫 개발팀이 되어 주는 것.
              <br />
              <br />
              이것이 팀 오메가의 미션입니다
            </Typography>
          </Grid>
        </Section3TypoGridItem>
      </Section3BackgroundBox>
    </CustomContainer>
  );
}

export function MainSection4Block() {
  return (
    <Section4BackgroundBox>
      <Section4Container disableGutters>
        <CustomGridContainer container alignContent="center" rowGap={3}>
          <Grid item xs={12}>
            <Typography variant="h4" lineHeight="2">
              팀 오메가는
              <br /> 비지니스 파트너로서 <br />
              새로운 플랫폼을 <br /> 만들고 있습니다
            </Typography>
          </Grid>
          {/* <Grid container item justifyContent="center" rowGap={1}>
            <Grid container item alignContent="center" alignItems="center">
              <Grid item xs={4}>
                <Typography variant="subtitle2" color="#238C66">
                  2021.07
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle1">팀 캐피탈레터 결성</Typography>
              </Grid>
            </Grid>
            <Grid container item alignContent="center" alignItems="center">
              <Grid item xs={4}>
                <Typography variant="subtitle2" color="#238C66">
                  2022
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle1">4개 플랫폼 런칭</Typography>
              </Grid>
            </Grid>
            <Grid container item alignContent="center" alignItems="center">
              <Grid item xs={4}>
                <Typography variant="subtitle2" color="#238C66">
                  2023
                </Typography>
              </Grid>
              <Grid container item xs={8} alignItems="center" columnGap={1}>
                <Grid item xs="auto">
                  <Typography variant="subtitle1">3개 플랫폼 런칭</Typography>
                </Grid>
                <Grid item xs="auto">
                  <Typography variant="caption1">(23.06 현재)</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}
        </CustomGridContainer>
      </Section4Container>
    </Section4BackgroundBox>
  );
}

export function MainSection5Block() {
  const { partners } = useContext(MainSection5Context);

  return (
    <Section5BackgroundBox>
      <Section5Container disableGutters maxWidth="xl">
        <CustomGridContainer container rowGap={12} alignContent="center">
          <Grid item xs={12}>
            <Typography variant="h4" lineHeight="63.55px" align="left">
              <Typography variant="h4" component="span" color="#238C66">
                다양한 플랫폼
              </Typography>
              이
              <br />팀 오메가와
              <br />
              함께 합니다
            </Typography>
          </Grid>
          <Grid container item xs={12} rowGap={6} columnSpacing={8} justifyContent="space-evenly">
            <Grid item xs={5} key={partners[0].alt}>
              <Section5LogoImageBox sx={{ alignItems: "flex-start" }}>
                <Section5LogoImage src={partners[0].image} alt={partners[0].alt} />
              </Section5LogoImageBox>
            </Grid>
            <Grid item xs={5} key={partners[1].alt}>
              <Section5LogoImageBox>
                <Section5LogoImage src={partners[1].image} alt={partners[1].alt} />
              </Section5LogoImageBox>
            </Grid>
            <Grid item xs={5} key={partners[2].alt}>
              <Section5LogoImageBox sx={{ alignItems: "flex-end" }}>
                <Section5LogoImage src={partners[2].image} alt={partners[2].alt} />
              </Section5LogoImageBox>
            </Grid>
            <Grid item xs={5} key={partners[3].alt}>
              <Section5LogoImageBox>
                <Section5LogoImage src={partners[3].image} alt={partners[3].alt} />
              </Section5LogoImageBox>
            </Grid>
            <Grid item xs={5} key={partners[4].alt}>
              <Section5LogoImageBox>
                <Section5LogoImage src={partners[4].image} alt={partners[4].alt} />
              </Section5LogoImageBox>
            </Grid>
            <Grid item xs={5} key={partners[5].alt}>
              <Section5LogoImageBox>
                <Section5LogoImage src={partners[5].image} alt={partners[5].alt} />
              </Section5LogoImageBox>
            </Grid>
          </Grid>
        </CustomGridContainer>
      </Section5Container>
    </Section5BackgroundBox>
  );
}
