import React from "react";
import { useParams } from "react-router-dom";
import WorksDetailBlockMySignal from "./WorksDetailBlockMySignal";
import WorksDetailBlockDoubleStudio from "./WorksDetailBlockDoubleStudio";
import WorksDetailBlockLinkey from "./WorksDetailBlockLinkey";
import WorksDetailBlockOn1016 from "./WorksDetailBlockOn1016";
import WorksDetailBlockProteinMusic from "./WorksDetailBlockProteinMusic";
import WorksDetailBlockSiktyling from "./WorksDetailBlockSiktyling";

export default function PortfolioDetailContainer() {
  const { workId } = useParams();

  if (workId === "mysignal") {
    return <WorksDetailBlockMySignal />;
  } else if (workId === "doublestudio") {
    return <WorksDetailBlockDoubleStudio />;
  } else if (workId === "linkey") {
    return <WorksDetailBlockLinkey />;
  } else if (workId === "on1016") {
    return <WorksDetailBlockOn1016 />;
  } else if (workId === "siktyling") {
    return <WorksDetailBlockSiktyling />;
  } else if (workId === "proteinmusic") {
    return <WorksDetailBlockProteinMusic />;
  }
}
