import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: (themeParam) => ({
        body: {
          "::-webkit-scrollbar": {
            display: "none",
          },
        },
      }),
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          subtitle1: "p",
          subtitle2: "p",
          body2SB: "p",
          body2S: "p",
          body3: "p",
          caption1: "p",
          caption1SB: "p",
          caption2: "p",
        },
      },
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1830,
    },
  },
  palette: {
    primary: {
      main: "#238C66",
    },
    secondary: {
      main: "#59C1BD",
      dark: "#4AC799",
    },
    text: {
      primary: "#000000",
      secondary: "#FFFFFF",
    },
    background: {
      default: "#FFFFFF",
      navy: "#1C315E",
    },
    grey: {
      50: "#F8F8F8",
      100: "#EAECEE",
      200: "#DCDFE1",
      300: "#D9D9D9",
      400: "#9DA0A1",
      500: "#606264",
      600: "#404244",
      700: "#212121",
      800: "#151515",
      900: "#0C0C0C",
    },
    neutral: {
      main: "#0C0C0C",
      contrastText: "#FDFDFD",
    },
  },
  typography: {
    fontFamily: ["LINESeedSansKR-Bold", "LINESeedSansKR-Regular", "Noto Sans KR"].join(","),

    h1: {
      fontSize: 96,
      fontWeight: 700,
      letterSpacing: "0.02em",
    },

    h2: {
      fontSize: 68,
      fontWeight: 700,
      letterSpacing: "0.02em",
    },
    h3: {
      fontSize: 48,
      fontWeight: 700,
      letterSpacing: "0.02em",
    },
    h4: {
      fontSize: 34,
      fontWeight: 700,
      letterSpacing: "0.02em",
    },
    h5: {
      fontSize: 24,
      fontWeight: 700,
      letterSpacing: "0.02em",
    },
    h6: {
      fontSize: 18,
      fontWeight: 700,
      letterSpacing: "0.02em",
    },

    subtitle1: {
      fontSize: 20,
      fontWeight: 400,
      letterSpacing: "0.02em",
    },
    subtitle2: {
      fontSize: 18,
      fontWeight: 400,
      letterSpacing: "0.02em",
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: "0.02em",
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: "0.02em",
    },
    body2SB: {
      fontSize: 14,
      fontWeight: 700,
      letterSpacing: "0.02em",
      fontFamily: ["DM Sans", "LINESeedSansKR-Bold", "Noto Sans KR"].join(","),
    },
    body2S: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: "0.02em",
      fontFamily: ["DM Sans", "LINESeedSansKR-Regular", "Noto Sans KR"].join(","),
    },
    body3: {
      fontSize: 13,
      fontWeight: 400,
      letterSpacing: "0.02em",
    },
    caption1: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: "0.02em",
    },
    caption1SB: {
      fontSize: 12,
      fontWeight: 700,
      letterSpacing: "0.02em",
      fontFamily: ["DM Sans", "LINESeedSansKR-Bold", "Noto Sans KR"].join(","),
    },
    caption2: {
      fontSize: 10,
      fontWeight: 400,
      letterSpacing: "0.02em",
    },
  },
});

export default theme;
