import React from "react";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import WorksFrame, { WorksStaffInfoFrame } from "../../components/WorksDetailFrame";
import { worksDetailUrl } from "../../configs/urls";

const DetailImage = styled("img")({
  width: "100%",
});

export default function WorksDetailBlockProteinMusic() {
  const StaffInfo = () => (
    <WorksStaffInfoFrame period="MAY-JUN 2023">
      <Grid container item rowGap={0.6}>
        <Grid item xs={12}>
          <Typography variant="body2SB">Project Leader</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2S">Kim Il Do</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2SB">Tech Leader</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2S">Song Jeong Won</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2SB">Front-End Developer</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2S">Choi Jeong Won</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2SB">Back-End Developer</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2S">Kim Young Hoon</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2SB">Back-End Developer</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2S">Park Sung Ju</Typography>
        </Grid>
      </Grid>
    </WorksStaffInfoFrame>
  );

  const MobileStaffInfo = () => (
    <WorksStaffInfoFrame period="MAY-JUN 2023">
      <Grid container item rowGap={0.6}>
        <Grid item xs={6}>
          <Typography variant="body2SB">Project Leader</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2S">Kim Il Do</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2SB">Tech Leader</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2S">Song Jeong Won</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2SB">Front-End Developer</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2S">Choi Jeong Won</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2SB">Back-End Developer</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2S">Kim Young Hoon</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2SB">Back-End Developer</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2S">Park Sung Ju</Typography>
        </Grid>
      </Grid>
    </WorksStaffInfoFrame>
  );

  return (
    <WorksFrame StaffInfo={StaffInfo} MobileStaffInfo={MobileStaffInfo}>
      <DetailImage src={worksDetailUrl.proteinMusic} />
    </WorksFrame>
  );
}
