import React from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import useMediaQuery from "@mui/material/useMediaQuery";

const BackgroundBox = styled(Box)({
  width: "100%",
  minHeight: "100vh",
  padding: "15vh 0",
  backgroundColor: "#FFFFFF",
  display: "relative",
});

const MobileBackgroundBox = styled(Box)({
  width: "100%",
  padding: "62px 0",
  minHeight: "100vh",
  backgroundColor: "#FFFFFF",
  display: "relative",
});

const SideBarContainer = styled(Container)({
  position: "fixed",
});

export default function WorksFrame(props) {
  const { StaffInfo, MobileStaffInfo } = props;

  const overLg = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  if (overLg) {
    return (
      <BackgroundBox>
        {/* Fixed sidebar */}
        <SideBarContainer disableGutters maxWidth={false}>
          <Container maxWidth="xl">
            <Grid container alignContent="flex-start" rowGap={5.5} xs={3}>
              <Grid item xs={12}>
                <Typography variant="h2" fontSize="64px" textTransform="uppercase">
                  works
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </SideBarContainer>
        <Container disableGutters maxWidth="xl">
          <Grid container>
            <Grid item xs={3} />
            <Grid container item xs={9}>
              <Grid container item xs={8}>
                {props.children}
              </Grid>
              <StaffInfo />
            </Grid>
          </Grid>
        </Container>
      </BackgroundBox>
    );
  } else {
    return (
      <MobileBackgroundBox>
        <Container disableGutters maxWidth="xl">
          <Grid container rowGap={2}>
            {props.children}
            <MobileStaffInfo />
          </Grid>
        </Container>
      </MobileBackgroundBox>
    );
  }
}

export function WorksStaffInfoFrame(props) {
  const { period } = props;

  const overLg = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  if (overLg) {
    return (
      <Grid container item xs={4} justifyContent="center" alignContent="flex-start" rowGap={5.5}>
        <Grid container item justifyContent="center">
          <Grid container item xs={6} rowGap={1.2}>
            <Grid item xs={12}>
              <Typography variant="caption1SB" color="primary">
                PROJECT
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2S">{period}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item justifyContent="center">
          <Grid container item xs={6} rowGap={1.2}>
            <Grid item xs={12}>
              <Typography variant="caption1SB" color="primary">
                TEAM OMEGA
              </Typography>
            </Grid>
            {props.children}
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container item justifyContent="center" alignContent="flex-start" rowGap={3}>
        <Grid container item justifyContent="center">
          <Grid container item xs={9} rowGap={1.2}>
            <Grid item xs={12}>
              <Typography variant="caption1SB" color="primary">
                PROJECT
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2S">{period}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item justifyContent="center">
          <Grid container item xs={9} rowGap={1.2}>
            <Grid item xs={12}>
              <Typography variant="caption1SB" color="primary">
                TEAM OMEGA
              </Typography>
            </Grid>
            {props.children}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
