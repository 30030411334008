import React from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// import ButtonBase from "@mui/material/ButtonBase";

const BackgroundBox = styled(Box)({
  width: "100%",
  minHeight: "100vh",
  padding: "15vh 0",
  backgroundColor: "#FFFFFF",
  display: "relative",
});

// const WorksGridContainer = styled(Grid)({
//   paddingTop: "25px",
// });

// const MenuButton = styled(ButtonBase)(({ active_state }) => ({
//   width: "100%",
//   paddingBottom: "12px",
//   borderBottom: active_state ? "2px solid #4AC799" : "2px solid #000000",
// }));

// const ButtonTypo = styled(Typography)({
//   width: "100%",
// });

const SideBarContainer = styled(Container)({
  position: "fixed",
});

export default function WorksFrame(props) {
  const { menuActiveState, menuActiveFunc } = props;

  return (
    <BackgroundBox>
      {/* Fixed sidebar */}
      <SideBarContainer disableGutters maxWidth={false}>
        <Container maxWidth="xl">
          <Grid container alignContent="flex-start" rowGap={5.5} xs={3}>
            <Grid item xs={12}>
              <Typography variant="h2" fontSize="64px" textTransform="uppercase">
                works
              </Typography>
            </Grid>
            {/* <Grid container item rowGap={2.5}>
              <Grid container item justifyContent="center">
              <Grid item xs={6}>
              <MenuButton active_state={menuActiveState === "platform"} onClick={() => menuActiveFunc("platform")}>
              <ButtonTypo variant="subtitle1" align="left">
              플랫폼
              </ButtonTypo>
              </MenuButton>
              </Grid>
              </Grid>
              <Grid container item justifyContent="center">
              <Grid item xs={6}>
              <MenuButton active_state={menuActiveState === "searchEngine"} onClick={() => menuActiveFunc("searchEngine")}>
              <ButtonTypo variant="subtitle1" align="left">
              서치엔진
                    </ButtonTypo>
                    </MenuButton>
                    </Grid>
                    </Grid>
                    <Grid container item justifyContent="center">
                    <Grid item xs={6}>
                    <MenuButton active_state={menuActiveState === "react"} onClick={() => menuActiveFunc("react")}>
                    <ButtonTypo variant="subtitle1" align="left">
                    리액트
                    </ButtonTypo>
                    </MenuButton>
                    </Grid>
                    </Grid>
                  </Grid> */}
          </Grid>
        </Container>
      </SideBarContainer>
      <Container disableGutters maxWidth="xl">
        <Grid container>
          <Grid item xs={3} />
          <Grid item xs>
            {props.children}
          </Grid>
        </Grid>
      </Container>
    </BackgroundBox>
  );
}
