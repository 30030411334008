import React from "react";

import UniversalAppBar from "../components/UniversalAppBar";
import UniversalAppFooter from "../components/UniversalAppFooter";
import AboutUs from "../containers/About/AboutUsContainer";

export default function AboutUsView() {
  return (
    <React.Fragment>
      <UniversalAppBar />
      <AboutUs />
      <UniversalAppFooter />
    </React.Fragment>
  );
}
