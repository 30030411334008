import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import MainView from "../views/MainView";
import AboutUsView from "../views/AboutUsView";
import ProjectsView from "../views/ProjectsView";
import WorksView from "../views/WorksView";
import WorksDetailView from "../views/WorksDetailView";
import ContactView from "../views/ContactView";

const createRoutes = (props) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<MainView />} />
        <Route exact path="/aboutus" element={<AboutUsView />} />
        <Route exact path="/projects" element={<ProjectsView />} />
        <Route exact path="/works" element={<WorksView />} />
        <Route exact path="/works/:workId" element={<WorksDetailView />} />
        <Route exact path="/contact" element={<ContactView />} />
      </Routes>
    </BrowserRouter>
  );
};

export default createRoutes;
