import React, { useEffect } from "react";

import AboutUsBlock from "./AboutUsBlocks";

export default function AboutUsContainer() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <AboutUsBlock />;
}
