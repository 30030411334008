// const kapitalLetterLogoUrl = "https://storage.googleapis.com/kapitalletter-landing/image/logo/kapitalLetter.svg";
const kapitalLetterLogoUrl = "/assets/logos/LOGO.svg";

const logoUrl = {
  mySignal: "https://storage.googleapis.com/kapitalletter-landing/image/logo/MySignal.svg",
  danaeum: "https://storage.googleapis.com/kapitalletter-landing/image/logo/danaeum.svg",
  doubleStudio: "https://storage.googleapis.com/kapitalletter-landing/image/logo/doubleStudio.svg",
  koreaUniv: "https://storage.googleapis.com/kapitalletter-landing/image/logo/koreaUniv.svg",
  linkey: "https://storage.googleapis.com/kapitalletter-landing/image/logo/linkey.svg",
  on1016: "https://storage.googleapis.com/kapitalletter-landing/image/logo/on1016.svg",
  siktyling: "https://storage.googleapis.com/kapitalletter-landing/image/logo/siktyling.svg",
};

const partnersLogoUrl = {
  mySignal: "/assets/logos/partner_logo/mysignal_logo.svg",
  danaeum: "/assets/logos/partner_logo/danaeum_logo.svg",
  doubleStudio: "/assets/logos/partner_logo/doublestudio_logo.svg",
  koreaUniv: "/assets/logos/partner_logo/koreauniversity_logo.svg",
  linkey: "/assets/logos/partner_logo/linkey_logo.svg",
  on1016: "/assets/logos/partner_logo/on1016_logo.svg",
};

const techStackUrl = {
  googleCloud: "/assets/logos/tech_stack/google_cloud_logo.svg",
  cloudFunction: "/assets/logos/tech_stack/cloud_function_logo.svg",
  firebase: "/assets/logos/tech_stack/firebase_logo.svg",
  react: "/assets/logos/tech_stack/react_logo.svg",
  mui: "/assets/logos/tech_stack/material_ui_logo.svg",
  typeSense: "/assets/logos/tech_stack/type_sense_logo.svg",
};

const backgroundUrl = {
  mainSection1: "/assets/images/main_section1.svg",
  mainSection3: "/assets/images/main_section3.png",
};

const worksThumbnailUrl = {
  mySignal: "/assets/images/thumbnail_my_signal.jpg",
  doubleStudio: "/assets/images/thumbnail_double_studio.jpg",
  linkey: "/assets/images/thumbnail_linkey.jpg",
  on1016: "/assets/images/thumbnail_on1016.jpg",
  siktyling: "/assets/images/thumbnail_siktyling.jpg",
  proteinMusic: "/assets/images/thumbnail_protein_music.jpg",
  // dummy: "/assets/images/dummy_thumbnail.svg",
};

const worksDetailUrl = {
  mySignal: "/assets/images/detail_my_signal.jpg",
  doubleStudio: "/assets/images/detail_double_studio.jpg",
  linkey: "/assets/images/detail_linkey.jpg",
  on1016: "/assets/images/detail_on1016.jpg",
  siktyling: "/assets/images/detail_siktyling.jpg",
  proteinMusic: "/assets/images/detail_protein_music.jpg",
};

export { kapitalLetterLogoUrl, logoUrl, partnersLogoUrl, techStackUrl, backgroundUrl, worksThumbnailUrl, worksDetailUrl };
