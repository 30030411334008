import React, { useState, useRef } from "react";

import { Link, useLocation } from "react-router-dom";

import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";

import MenuIcon from "@mui/icons-material/Menu";

import { kapitalLetterLogoUrl } from "../configs/urls";

const CustomAppBar = styled(AppBar)({
  backgroundColor: "#ffffff",
  padding: "10px 0",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.05)",
});

const AppBarContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    padding: "0 150px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "0 30px",
  },
}));

const MobileMenuGrid = styled(Grid)({
  padding: "40px 0",
});

export default function UniversalAppBar() {
  const location = useLocation();
  const pathName = location.pathname;

  const mobileMenuRef = useRef(null);

  const [mobileMenuOpenState, mobileMenuOpenSetState] = useState(false);

  const mobileMenuOpenEvent = () => {
    mobileMenuOpenSetState(true);
  };

  const mobileMenuCloseEvent = () => {
    mobileMenuOpenSetState(false);
  };

  const overLg = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  if (overLg) {
    return (
      <CustomAppBar>
        <AppBarContainer disableGutters maxWidth="xl">
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs="auto">
              <ButtonBase LinkComponent={Link} to="/">
                <img src={kapitalLetterLogoUrl} alt="로고" />
              </ButtonBase>
            </Grid>
            <Grid container item xs justifyContent="flex-end" alignItems="center" columnSpacing={overLg ? 16 : 5}>
              <Grid item xs="auto">
                <ButtonBase LinkComponent={Link} to="/works">
                  <Typography variant="h6" fontSize="15px" color={pathName === "/works" ? "primary" : "textPrimary"}>
                    WORKS
                  </Typography>
                </ButtonBase>
              </Grid>
              <Grid item xs="auto">
                <ButtonBase LinkComponent={Link} to="/contact">
                  <Typography variant="h6" fontSize="15px" color={pathName === "/contact" ? "primary" : "textPrimary"}>
                    CONTACT
                  </Typography>
                </ButtonBase>
              </Grid>
            </Grid>
          </Grid>
        </AppBarContainer>
      </CustomAppBar>
    );
  } else {
    if (mobileMenuOpenState) {
      return (
        <CustomAppBar>
          <AppBarContainer disableGutters>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item xs="auto">
                <ButtonBase LinkComponent={Link} to="/">
                  <img src={kapitalLetterLogoUrl} alt="로고" />
                </ButtonBase>
              </Grid>
              <Grid item xs="auto">
                <IconButton ref={mobileMenuRef} onClick={mobileMenuCloseEvent}>
                  <MenuIcon />
                </IconButton>
              </Grid>
              <MobileMenuGrid container item alignContent="center" rowGap={4}>
                <Grid item xs={12}>
                  <ButtonBase LinkComponent={Link} to="/works">
                    <Typography variant="h5" color="textPrimary">
                      WORKS
                    </Typography>
                  </ButtonBase>
                </Grid>
                <Grid item xs={12}>
                  <ButtonBase LinkComponent={Link} to="/contact">
                    <Typography variant="h5" color="textPrimary">
                      CONTACT
                    </Typography>
                  </ButtonBase>
                </Grid>
              </MobileMenuGrid>
            </Grid>
          </AppBarContainer>
        </CustomAppBar>
      );
    } else {
      return (
        <CustomAppBar>
          <AppBarContainer disableGutters>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item xs="auto">
                <ButtonBase LinkComponent={Link} to="/">
                  <img src={kapitalLetterLogoUrl} alt="로고" />
                </ButtonBase>
              </Grid>
              <Grid item xs="auto">
                <IconButton ref={mobileMenuRef} onClick={mobileMenuOpenEvent}>
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Grid>
          </AppBarContainer>
        </CustomAppBar>
      );
    }
  }
}
