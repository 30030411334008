import React from "react";

import UniversalAppBar from "../components/UniversalAppBar";
import UniversalAppFooter from "../components/UniversalAppFooter";
import Main from "../containers/Root/MainContainer";

export default function MainView() {
  return (
    <React.Fragment>
      <UniversalAppBar />
      <Main />
      <UniversalAppFooter />
    </React.Fragment>
  );
}
