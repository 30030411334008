import React from "react";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";

import WorksCard from "../../components/WorksCard";
import WorksFrame from "../../components/WorksFrame";

const WorksGridContainer = styled(Grid)({
  paddingTop: "25px",
});

export default function WorksBlock(props) {
  const { worksDataSet, openLinkFunc, menuActiveState, menuActiveFunc } = props;

  return (
    <WorksFrame menuActiveState={menuActiveState} menuActiveFunc={menuActiveFunc}>
      <WorksGridContainer container rowGap={6.5} columnSpacing={6.5} xs={11.5}>
        {Object.keys(worksDataSet).map((workKey) => {
          const propsSet = {
            name: worksDataSet[workKey].name,
            description: worksDataSet[workKey].description,
            thumbnail: worksDataSet[workKey].thumbnail,
          };

          return (
            <Grid item xs={4}>
              <WorksCard propsSet={propsSet} openLinkFunc={() => openLinkFunc(workKey)} />
            </Grid>
          );
        })}
      </WorksGridContainer>
    </WorksFrame>
  );
}
