import react from "react";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";

const CardGridContainer = styled(Grid)({
  "&:hover": {
    transform: "translateY(-20px)",
    transition: "all 0.5s ease-in-out",
  },
});

const ThumbnailImage = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  aspectRatio: "1/1",
});

export default function WorksCard(props) {
  const { openLinkFunc, propsSet } = props;

  return (
    <CardGridContainer container component={ButtonBase} onClick={openLinkFunc} rowGap={3}>
      <Grid item xs={12}>
        <ThumbnailImage src={propsSet.thumbnail} />
      </Grid>
      <Grid container item rowGap={1.5}>
        <Grid item xs={12}>
          <Typography variant="h6" align="left" noWrap>
            {propsSet.name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" align="left" noWrap>
            {propsSet.description}
          </Typography>
        </Grid>
      </Grid>
    </CardGridContainer>
  );
}
