import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import WorksBlock from "./WorksBlock";
import WorksMobileBlock from "./WorksMobileBlock";

import useMediaQuery from "@mui/material/useMediaQuery";

import { worksThumbnailUrl } from "../../configs/urls";

const worksDataSet = {
  mysignal: {
    thumbnail: worksThumbnailUrl.mySignal,
    name: "마이시그널",
    description: "마이시그널은 유튜브, 틱톡, 기타 상업 광고등의 영상에 사용되는 브랜드 시그널 및 배경에 사용될 음원을 자체 제공하는 서비스 입니다",
  },
  doublestudio: {
    thumbnail: worksThumbnailUrl.doubleStudio,
    name: "Double Studio",
    description: "더블스튜디오는 대형 led 스크린으로 영상물 촬영에 필요한 다양한 형태의 배경 등을 led 스크린에 구현한 채로 촬영 가능한 차세대 제작 스튜디오 입니다. 캐피탈레터는 더블스튜디오의 소개와 예약을 서비스 하는 홈페이지를 제작하였습니다.",
  },
  linkey: {
    thumbnail: worksThumbnailUrl.linkey,
    name: "Linkey",
    description: "링키는 번역가를 필요로 하는 기업과 번역 관련 일자리가 필요한 국내 거주 외국인을 매칭해주는 서비스입니다.",
  },
  on1016: {
    thumbnail: worksThumbnailUrl.on1016,
    name: "ON1016",
    description: "ON1016은 경력 보유 여성을 위한 일자리 매칭 서비스입니다. 경력 단절 여성을 대상으로 하는 만큼, 집에서 시간적인 여유를 가지고 접근 가능한 온라인 리뷰 프로젝트를 전문적으로 서비스하고 있습니다.",
  },
  siktyling: {
    thumbnail: worksThumbnailUrl.siktyling,
    name: "식타일링",
    description: "식타일링은 AI를 통해 상품을 추천하는 온라인 식재료 쇼핑몰 입니다. CAFE24 쇼핑몰 솔루션에 커스터마이징을 더하여 효율적인 웹서비스를 개발 하였습니다.",
  },
  proteinmusic: {
    thumbnail: worksThumbnailUrl.proteinMusic,
    name: "Protein Music",
    description: "Protein music은 상업적 이용이 가능한, 저작권 제한이 없는 음원을 검색할 수 있는 통합 검색 엔진 서비스입니다.",
  },
};

export default function WorksContainer() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const openLinkFunc = (docId) => {
    navigate("/works/" + docId);
  };

  const [menuActiveState, menuActiveSetState] = useState("platform");

  const menuActiveFunc = (menu) => {
    menuActiveSetState(menu);
  };

  const overXL = useMediaQuery((theme) => theme.breakpoints.up("xl"));

  if (overXL) {
    return <WorksBlock worksDataSet={worksDataSet} openLinkFunc={openLinkFunc} menuActiveState={menuActiveState} menuActiveFunc={menuActiveFunc} />;
  } else {
    return <WorksMobileBlock worksDataSet={worksDataSet} openLinkFunc={openLinkFunc} menuActiveState={menuActiveState} menuActiveFunc={menuActiveFunc} />;
  }
}
