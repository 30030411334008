import React from "react";
import { Link } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import ButtonBase from "@mui/material/ButtonBase";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";

import { kapitalLetterLogoUrl } from "../configs/urls";

const FooterContainerGrid = styled(Grid)({
  padding: "63px 160px 80px 170px",
});

const MobileFooterContainerGrid = styled(Grid)({
  padding: "20px 10px",
});

const FooterTextTypo = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
}));

export default function UniversalAppFooter() {
  const breakpointLg = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  if (breakpointLg) {
    return (
      <Container disableGutters maxWidth="xl">
        <FooterContainerGrid container>
          <Grid container item xs={6} alignContent="space-between" rowGap={3}>
            <Grid container item xs={10} columnGap={3.5}>
              <Grid item xs="auto">
                <FooterTextTypo variant="caption1">06914 서울시 동작구 만양로 8길 59 주식회사 캐피탈레터</FooterTextTypo>
              </Grid>
              <Grid item xs="auto">
                <FooterTextTypo variant="caption1">대표이사 김일도</FooterTextTypo>
              </Grid>
              <Grid item xs="auto">
                <FooterTextTypo variant="caption1">사업자등록번호 349-87-02618</FooterTextTypo>
              </Grid>
              <Grid item xs="auto">
                <FooterTextTypo variant="caption1">일반 문의 02-812-3399</FooterTextTypo>
              </Grid>
              <Grid item xs="auto">
                <FooterTextTypo variant="caption1">영업 문의 010-3283-8123</FooterTextTypo>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption1">© KAPITAL LETTER Inc. All right reserved.</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={6} justifyContent="flex-end" alignContent="flex-start">
            <Grid container item xs={11} rowGap={1} justifyContent="center">
              <Grid item xs={12}>
                <Typography variant="body2">문의 contact@kapitalletter.com</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">채용 apply@kapitalletter.com</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">투자 및 언론 보도 idkim@kapitalletter.com</Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid container item xs={2} justifyContent="flex-end">
            <Grid container item xs={9} columnSpacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle3">Social</Typography>
              </Grid>
              <Grid item xs="auto">
                <FacebookIcon />
              </Grid>
              <Grid item xs="auto">
                <InstagramIcon />
              </Grid>
              <Grid item xs="auto">
                <YouTubeIcon />
              </Grid>
            </Grid>
          </Grid> */}
        </FooterContainerGrid>
      </Container>
    );
  } else {
    return (
      <Container disableGutters>
        <MobileFooterContainerGrid container>
          <Grid container item xs={12} rowGap={2}>
            <Grid container item rowGap={2}>
              {/* <Grid container item justifyContent="center">
                <Grid item xs="auto">
                  <ButtonBase LinkComponent={Link} to="/">
                    <img src={kapitalLetterLogoUrl} alt="로고" />
                  </ButtonBase>
                </Grid>
              </Grid> */}
              <Grid container item justifyContent="center" columnGap={3}>
                <Grid item xs="auto">
                  <FooterTextTypo variant="caption1">일반 문의 02-812-3399</FooterTextTypo>
                </Grid>
                <Grid item xs="auto">
                  <FooterTextTypo variant="caption1">영업 문의 010-3283-8123</FooterTextTypo>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption1" align="center">
                © KAPITAL LETTER Inc. All right reserved.
              </Typography>
            </Grid>
          </Grid>
        </MobileFooterContainerGrid>
      </Container>
    );
  }
}
