import React from "react";

import UniversalAppBar from "../components/UniversalAppBar";
import UniversalAppFooter from "../components/UniversalAppFooter";
import Contact from "../containers/Contact/ContactContainer";

export default function ContactView() {
  return (
    <React.Fragment>
      <UniversalAppBar />
      <Contact />
      <UniversalAppFooter />
    </React.Fragment>
  );
}
