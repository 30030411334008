import React, { useEffect, useRef } from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const ContactBackgroundBox = styled(Box)({
  backgroundColor: "#FFFFFF",
  width: "100vw",
  height: "80vh",
});

const CustomContainer = styled(Container)({
  height: "100%",
  paddingTop: "62px",
});

const CustomGridContainer = styled(Grid)({
  height: "100%",
});

const ContainerPaper = styled(Paper)({
  padding: "80px",
  borderRadius: "30px",
  background: "linear-gradient(90deg, #FFFFFF 73%, #238C66 27%)",
});

const MapBox = styled(Box)({
  height: "40vh",
});

export default function ContactBlock() {
  const mapRef = useRef(null);

  useEffect(() => {
    const { naver } = window;
    if (!mapRef.current || !naver) return;

    const geolocation = [37.5101809, 126.9460334];
    const location = new naver.maps.LatLng(geolocation[0], geolocation[1]);

    const mapOptions = {
      center: location,
      zoom: 15,
      zoomControl: true,
      scaleControl: false,
    };
    const map = new naver.maps.Map(mapRef.current, mapOptions);
    new naver.maps.Marker({
      position: location,
      map,
    });
  }, []);

  return (
    <ContactBackgroundBox>
      <CustomContainer maxWidth="lg">
        <CustomGridContainer container alignContent="center" rowGap={4} columnSpacing={1}>
          <Grid item xs={12}>
            <ContainerPaper elevation={7}>
              <Grid container columnSpacing={2}>
                <Grid container item xs={6} direction="column">
                  <Grid item xs="auto">
                    <Typography variant="h3" color="primary" fontFamily={["LatoBlackItalic", "LineSeedKR", "Noto Sans KR"].join(",")}>
                      Contact
                    </Typography>
                  </Grid>
                  <Grid container item xs direction="column" justifyContent="center" rowGap={4}>
                    <Grid container item>
                      <Grid item xs={12}>
                        <Typography variant="h6" color="primary">
                          E-mail
                        </Typography>
                      </Grid>
                      <Grid item xs={0.3} />
                      <Grid item xs>
                        <Typography variant="h6">contact@kapitalletter.com</Typography>
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid item xs={12}>
                        <Typography variant="h6" color="primary">
                          Phone
                        </Typography>
                      </Grid>
                      <Grid item xs={0.3} />
                      <Grid item xs>
                        <Typography variant="h6">02 812 3399</Typography>
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid item xs={12}>
                        <Typography variant="h6" color="primary">
                          Address
                        </Typography>
                      </Grid>
                      <Grid item xs={0.3} />
                      <Grid item xs>
                        <Typography variant="h6">서울시 동작구 만양로 8길 59, 3층</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <MapBox ref={mapRef} />
                </Grid>
              </Grid>
            </ContainerPaper>
          </Grid>
        </CustomGridContainer>
      </CustomContainer>
    </ContactBackgroundBox>
  );
}
