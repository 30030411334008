import React from "react";

import UniversalAppBar from "../components/UniversalAppBar";
import UniversalAppFooter from "../components/UniversalAppFooter";
import WorksDetail from "../containers/WorksDetail/WorksDetailContainer";

export default function PortfolioDetailView() {
  return (
    <React.Fragment>
      <UniversalAppBar />
      <WorksDetail />
      <UniversalAppFooter />
    </React.Fragment>
  );
}
