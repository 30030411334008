import React, { useEffect } from "react";

import ContactBlock from "./ContactBlock";
import ContactMobileBlock from "./ContactMobileBlock";

import useMediaQuery from "@mui/material/useMediaQuery";

export default function ContactContainer() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const overLg = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  if (overLg) {
    return <ContactBlock />;
  } else {
    return <ContactMobileBlock />;
  }
}
