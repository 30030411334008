import React from "react";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import WorksCard from "../../components/WorksCard";

const BackgroundBox = styled(Box)({
  width: "100%",
  minHeight: "100vh",
  backgroundColor: "#FFFFFF",
  display: "relative",
  padding: "30px",
});

const WorksGridContainer = styled(Grid)({
  paddingTop: "70px",
  height: "100%",
});

export default function WorksMobileBlock(props) {
  const { worksDataSet, openLinkFunc } = props;

  return (
    <BackgroundBox>
      <WorksGridContainer container rowGap={4}>
        <Grid item xs={12}>
          <Typography variant="h2" fontSize="64px" textTransform="uppercase">
            works
          </Typography>
        </Grid>
        <Grid container item rowGap={2} columnSpacing={2}>
          {Object.keys(worksDataSet).map((workKey) => {
            const propsSet = {
              name: worksDataSet[workKey].name,
              description: worksDataSet[workKey].description,
              thumbnail: worksDataSet[workKey].thumbnail,
            };

            return (
              <Grid item xs={6}>
                <WorksCard propsSet={propsSet} openLinkFunc={() => openLinkFunc(workKey)} />
              </Grid>
            );
          })}
        </Grid>
      </WorksGridContainer>
    </BackgroundBox>
  );
}
